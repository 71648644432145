.btn-span, .btn-span:hover {
    background: #1e7735 !important; //#1f8a3b
    color: #fff !important;
}
.btn-span span {
    font-weight: 400;
    border-radius: 10;
    font-size: 22px;
    @media screen and (max-width: 768px) {
        font-weight: 400;
      }
}
