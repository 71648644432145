.container-paiement {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  @media screen and (max-width: 768px) {
    padding: 40px 10px;
  }
}

.step-oeprator-choice-block {
  margin-bottom: 90px;
  margin-top: 50px;
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
    margin-top: 20px;
  }
}
.step-operator-card {
  
  @media screen and (max-width: 768px) {
    background-color: transparent !important;
    margin-top: 0px;
  }
}

.paiement {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  background: #fff;
  border-radius: 8px;
}

.step-operator-title {
  @media screen and (max-width: 768px) {
    margin-left: 10px;
  }
}

.img-om {
  max-width: 100%;
  height: 40px;
  padding-left: 20px;
  margin-top: 5px;
}

.text-om {
  color: #1d1d1fe0;
  font-size: 20px;
  font-weight: bold;
}

.step-operator-logo-text {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  gap: 10px;
}

.arrow {
  color: #1f8a3b;
  padding-right: 3px;
}

.line-height {
  border: 1px solid #e6e6e6cc;
  width: 96%;
}
