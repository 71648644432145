

.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px !important; //50px
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    margin-top: 0px !important;
  }
  
}

.footer-logo {
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.img-footer {
  display: flex;
  //width: 80px;
  height: 45px;
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
    width: 120px;
    height: 45px;
  }
}

.footer-text {
  display: flex;
  flex-direction: column;
  color: #6c757d;
  padding-left: 15px;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.footer-text-mobile {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    display: flex;
    flex-direction: column;
    color: #6c757d;
    padding-left: 15px;
    padding: 0;
  }
  
}
.footer-text-mobile span {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.text-footer {
  font-size: 18px;
}

.footer-copyright {
  text-align: center;
  color: #6c757d;
  font-size: 18px;
  @media screen and (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    font-size: 12px;
  }
}
// .footer {
//   background-color: #eff0f3;
//   padding: 20px;
//   text-align: center;
// }

// .footer-content {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }

// .footer-logo {
//   display: flex;
//   //   align-items: center;
//   //   margin-bottom: 10px;
// }

// .footer-logo img {
//   max-width: 100px;
//   margin-bottom: 5px;
// }

// .footer-text {
//   margin: 0;
//   color: #6c757d;
//   //   padding-left: 10px;
// }

// .footer-copyright {
//   border-top: 1px solid slategrey;
//   width: 100%;
//   padding: 10px;
// }

// .footer-content p {
//   margin: 0;
//   color: #6c757d;
//   font-size: 14px;
// }
