
.step-vt-title {
  font-size: 18px;
  height: 28px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
  @media screen and (max-width: 768px) {
    margin-left: 10px;
  }
}

.ticket-container {
  display: block;
  align-items: center;
  //margin: 40px;
  margin: 10px 40px;
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
}

.card-titre {
  font-size: 20px;
  color: #1d1d1f;
  margin-top: 0px;
  margin-bottom: 5px;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}

#success {
  height: 48;
  width: 100%;
  background: #1f8a3b;
  color: #fff;
  border-radius: 10;
  font-weight: 400;
}

.step-verification-card {
  
  @media screen and (max-width: 768px) {
    background-color: transparent !important;
    margin-top: 0px;
  }
}

input[inputmode="numeric"]::placeholder {
  font-size: 16px;
  //color: rgba(0, 0, 0, 0.88)
}

.ant-form-item {
  margin-bottom: 18px;
}
