#step4 {
  height: 80px;
  display: block !important;
}

#step4_ {
  height: 250px;
  display: block !important;
}

.step-phone-card {
  @media screen and (max-width: 768px) {
    background: transparent;
  }
}
.step-phone-section {
  margin: 20px 40px;
  display: flex !important;
  flex-direction: column !important;
  gap: 20px;
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
}

.step-phone-section_ {
  gap: 10px !important;
}

.step-mobile-number-block {
  margin-bottom: 10px;
  margin-top: 20px;
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }
}

.step-phone-card-title {
  font-size: 22px;
  color: #1d1d1f;
  margin-top: 0px;
  margin-bottom: 0px;
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
}
/* .ant-form-item {
  display: block !important;
} */

.ant-input-outlined {
  width: 98%;
}

.code-de-confirmation {
  display: flex;
  align-items: start;
  gap: 10px;
}

.saisieNum {
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-bottom: 20px;
  gap: 5px;
}

.bulle-circle {
  //padding-bottom: 50px;
  opacity: 50%;
}

.infos {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
}

.step-phone-bold-label {
  font-weight: bold;
  //font-size: 18px;
  //padding-left: 3px;
  color: #ed8a26;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.mb-35 {
  margin-bottom: 35px;
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }
}

#basic_dateTicket {
  font-weight: bold !important;
  font-size: 20px !important;
}
