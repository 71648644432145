.step-success-card {
    padding: 40px;
    background: #fff;
    border-radius: 10px;
    @media screen and (max-width: 768px) {
        padding: 0;
      }
}

.step-success-card-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 40px 40px 40px;
    @media screen and (max-width: 768px) {
        padding: 0 20px 20px 20px;
      }
}

.details-title {
    font-weight: 400;
    font-size: 30px;
    text-align: center;
    padding-bottom: 40px;
    @media screen and (max-width: 768px) {
        font-size: 28px;
    }
}
.details-item {
    display:flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
    color: #1D1D1F;
    opacity: 0.65;
    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
}

.tk-contact-us {
    font-size: 22px;
    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
}