
// body {
//   background-color: #eff0f3;
// }

.no-display-on-mobile {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

#retours {

}
// .steps-header {
//   display: flex;
//   flex-direction: column;
//   @media screen and (max-width: 768px) {
//     flex-direction: row;
//     width: 100%;
//     align-items: center;
//     margin: 0 10px;
//     justify-content: space-between;
//   }
// }

// .steps-header {
//   display: flex;
//   flex-direction: column;
//   @media screen and (max-width: 768px) {
//     flex-direction: row;
//     width: 100%;
//     align-items: center;
//     margin: 0 10px;
//     justify-content: space-between;
//   }
// }

.steps-header {
  display: flex;
  //flex-direction: column;
  margin-top: 5px;
  @media screen and (max-width: 768px) {
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin: 0 10px;
    justify-content: space-between;
  }
}

.steps-block-w{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 42%;
  @media screen and (max-width: 768px) {
    width: 95%;
    margin: 0 10px;
  }
}
.back-link {
  display: flex;
  align-items: center;
}

.text-back-link {
  color: #1f8a3b;
  font-size:18px;
  @media screen and (max-width: 768px) {
    display: none;
    color: #000;
  }
}

.retour {
  font-size: 20px;
}

// .progess-bar {
//   display: flex;
//   margin-bottom: 30px;
//   @media screen and (max-width: 768px) {
//     display:inline-block;
//     width: 60%;
//     margin: 0;
//   }
// }

.progess-bar {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  @media screen and (max-width: 768px) {
    display:inline-block;
    margin-bottom: 30px;
    width: 60%;
    margin: 0;
  }
}

.progess-bar-count {
  width: 30px;
  font-size: 16px;
  font-weight: bold;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.step-header-close {
  display: none;
  @media screen and (max-width: 768px) {
    display: inline-block;
    margin: 0 20px;
  }
}
/*============= ANT STYLE ============*/
#step1 .ant-input,
#step1 .ant-input:focus, #step4 .ant-input, #step4 .ant-input:focus {
  background: transparent;
  border: none;
  margin-bottom: 10px;
  outline: none !important;
}

#step4 .ant-input-outlined {
  outline: none !important;
  border: none !important;
  background: transparent;
  margin-bottom: 10px;
}

.ant-input {
  height: 40px;
  font-size: 20px;
}

