.header {
  display: flex;
  justify-content: space-between;
  // width: 100%;
  align-items: center;
  margin: 0 100px;
  /*****md**********/
  @media screen and (max-width: 768px) {
    margin: 0 10px;
  }
}

.header-faq-btn {
  background-color: #32b551;
  border-radius: 8px;
  height: 40px; //48px;
  width: 100px; //130px;
  color: #fff;
  font-size: 20px;
  border: 1px solid #32b551;
  // margin-right: 30px;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.menu-item-home {
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.menu-item-home-mobile {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    margin-right: 10px;
  }
}

.nav-link {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
}

.header-logo {
  //width: 120px;
  height: 65px; //38px;
  @media screen and (max-width: 768px) {
    height: 35px; //28px;;
  }
}

.navbar {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-navbar {
  width: 100%;
  position: fixed;
  z-index: 1000;
  background-color: #1e7735;
  height: 80px;
  @media screen and (max-width: 768px) {
    background-color: #1f8a3b;
  }
}

.mr-auto {
  display: flex;
  list-style: none;
}

.link {
  color: #ffffff !important;
  margin-left: 20px;
  font-size: 20px;
  text-decoration: none;
}

.btn-faq {
  display: flex;
  align-items: center;
}

.header-faq-btn:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

// MENU DRAWER
.ant-menu-light.ant-menu-root.ant-menu-inline {
  border-inline-end: unset !important;
}

.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-inline-end: unset !important;
}
