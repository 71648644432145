.ant-result-error {
  background-color: #ffffff;
  margin-top: 40px;
  // height: 120px;
  // display: block !important;
  // width: 100% !important;
  // padding: 0px !important;
  // border-radius: 10px !important;
}

.step-error-card {
  padding: 40px;
  background: #fff;
  border-radius: 10px;
  @media screen and (max-width: 768px) {
    margin: 0 10px;
    padding: 40px 20px;
  }
}

.step-error-info {
  width: 364px;
  color: #E81F2BE0;
  font-weight: 400;
  font-size: 32px;
  text-align: center;
  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 25px;
  }
}

.step-error-info span {
  font-weight: 400;
  @media screen and (max-width: 768px) {
    //padding: 0 20px;
  }
}

.step-error-message {
  width: 364px;
  color: #1D1D1FE0;
  font-weight: 400;
  font-size: 22px;
  text-align: center;
  @media screen and (max-width: 768px) {
    width: 300px;
    font-size: 18px;
  }
}

.step-error-contact {
  font-size: 22px;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}
