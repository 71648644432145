@font-face {
  font-family: "ITCGothic";
  src: url("assets/font/lonaci/ITCGothic.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Geometos-Rounded";
  src: url("assets/font/lonaci/Geometos-Rounded.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "ITCGothic", sans-serif;
}

/* @font-face {
  font-family: "Roboto";
  src: url("assets/font/Roboto/Roboto-Italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("assets/font/Roboto/Roboto-Regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
} */

body {
  margin: 0;
  font-family: "ITCGothic", "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EFF0F3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ----- ROBOTO ------ */
.roboto-thin {
  font-family: "ITCGothic", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-regular {
  font-family: "ITCGothic", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "ITCGothic", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "ITCGothic", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "ITCGothic", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "ITCGothic", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "ITCGothic", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-light, p, span {
  font-family: "ITCGothic", sans-serif;
  font-weight: 300;
  /* font-size: 16px; */
}

.roboto-regular-italic {
  font-family: "ITCGothic", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "ITCGothic", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "ITCGothic", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "ITCGothic", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.roboto-light {
  font-family: "ITCGothic", sans-serif;
  font-weight: 300;
  font-style: normal;
}
