.ant-input {
  margin-bottom: 0px !important;
  //height: 55px;
}

.ant-card-body {
  display: block !important;
  width: 100% !important;
  padding: 0px !important;
  border-radius: 10px !important;
}

.ant-select-selector {
  background-color: #eff0f3 !important;
  border: none !important;
  @media screen and (max-width: 768px) {
    background-color: #e3e6ee !important;
  }
}
/***********INPUT NUMBER***********/
.ant-input-filled {
  color: #000000 !important;
  font-size: 18px !important;
  font-weight: bold !important;
}

.ant-input-outlined {
  color: #000000 !important;
  font-size: 18px !important;
  font-weight: bold !important;
  margin-left: 8px !important;
}

.ant-select-selection-item {
  color: #000000 !important;
  font-weight: bold !important;
  font-size: 16px !important;
  font-family: Roboto !important;
}

.ant-picker {
  background-color: #eff0f3 !important;
  border: none !important;
  @media screen and (max-width: 768px) {
    background-color: #e3e6ee !important;
  }
}

.ant-picker-large .ant-picker-input > input {
  font-size: 18px;
  font-weight: bold !important;
  line-height: 1.5;
}

.ant-row label {
  padding-left: 10px;
  font-weight: 500 !important;
  font-size: 18px !important;
  color: #0000007d !important;
}

// ===== ANT FORM ITEM ====
.ant-form-item {
  background-color: #eff0f3 !important;
  border-radius: 10px !important;
  display: inline-block !important;
  width: 100% !important;
  padding-top: 8px;

  @media screen and (max-width: 768px) {
    background-color: #e3e6ee !important;
  }
}
.ant-form-item-label {
  padding-bottom: 0px !important;
}

.ant-form-item .ant-form-item-label {
  text-align: left !important;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: inline-block !important;
  margin-inline-end: 4px !important;
  color: #ff4d4f !important;
  font-size: 14px !important;
  font-family: SimSun, sans-serif !important;
  line-height: 1 !important;
  content: "" !important;
}

// .ant-form-item .ant-form-item-label > label::after {
//   content: "*" !important;
//   color: #ff4d4f !important;
//   line-height: 1 !important;
//   font-family: SimSun, sans-serif !important;
//   font-size: 14px !important;
// }

.ant-form-item-control-input {
  height: 30px;
}

// 2.
// .ant-form-item-label {
//   position: relative;
//   //left: 12px;
//   top: 53px;
// }
// .ant-form-item-label label {
//   font-weight: normal;

//   pointer-events: none;

//   transition: 0.2s ease all;
//   color: gray;
//   z-index: 9999999;
// }

// .input-on-focus {
//   padding-top: 15px !important;
// }

// .form-item-on-focus {
//   position: relative;
//   top: 43px !important;
// }
