.home-hero-text {
  display: flex;
  flex-direction: column;
  font-size: 70px;
  font-weight: 700;
  margin-top: 50px;
  //margin-bottom: 50px;
  letter-spacing: -3px;
  @media screen and (max-width: 768px) {
    padding: 40px 30px 0px 30px;
    font-size: 40px;
    letter-spacing: -2px;
    line-height: 1.1em;
    margin-bottom: 20px;
    margin-top: 0px;
  }
}
.operator-img {
  width: 500px;
  @media screen and (max-width: 768px) {
    width: 300px;
    margin-bottom: 20px;
  }
}


.hero-page-span-title {
  font-family: "Geometos-Rounded";
  font-size: 62px;
  @media screen and (max-width: 768px) {
    font-size: 35px;
  }

  // @media screen and (max-width: 1024px) {
  //   font-size: 20px;
  // }
}

.home-hero-text span {
  font-weight: bold;
}

.btn-verifier-ticket {
  margin-top: 10px;
  width: 400px;
  height: 51px;
  border-radius: 10px;
  background: #1F8A3B; //#32b551;
  border: 2px solid #06641cbd;
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    margin: 10px 0 30px 0;
    width: 250px;
  }
}

.btn-bottom-verify-ticket {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 50px;
  height: 60px;
  @media screen and (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 10;
  }
}
.content-accueil {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 30px 110px;
  @media screen and (max-width: 768px) {
    padding: 10px 20px;
  }
}

.title-content {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
  @media screen and (max-width: 768px) {
    margin-bottom: 5px;
  }
}

.title-content-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35em;
  margin-top: 30px;
  margin-bottom: 15px;
  gap: 10px;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    gap: 0px;
  }
}

.title-36 {
  font-size: 45px;
  font-weight: 600;
  color: #1d1d1f;
  opacity: 0.88;
  color: #ed8a26;
  @media screen and (max-width: 768px) {
    font-size: 30px;
    padding: 10px 0;
  }
}

.blocs-steps {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.step {
  display: flex;
  flex-direction: column;
}

.home-step-title {
  align-items: center;
  display: flex;
  gap: 8px;
  margin-bottom: 15px;
  @media screen and (max-width: 768px) {
    gap: 15px;
    align-items: start;
    justify-content: center;
  }
}
.home-step-title span {
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  text-align: left;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
}

.step-center-style {
  margin: 0 3em;
  //width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    margin: 20px 0;
  }
}

.step-w70 {
  //width: 80%;
  @media screen and (max-width: 768px) {
    width: auto;
  }
}

.step-chiffre-title {
  justify-content: center;
  text-align: center;
  padding: 3px;
  color: #fff;
  font-weight: bold;
  background-color: #1f8a3b;
  border-radius: 7px;
  width: 40px;
  height: 40px;
  font-size: 30px;
  @media screen and (max-width: 768px) {
    width: 30px;
    height: 30px;
    font-size: 20px;
    font-weight: bold;
  }
}

.step-chiffre-title-2 {
  font-size: 28px;
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
}

.step-texte {
  text-align: start;
  font-size: 18px;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
}

.step-center-texte-style {
  //width: 90%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.style-title {
  font-size: 19px;
  text-align: center;
  @media screen and (max-width: 768px) {
    padding-top: 0px;
  }
}

// .text-steps {
//   display: flex;
//   flex-direction: column;
//   padding-top: 15px;
// }

/**********QUESTIONS FREQUENTES*****************/
.block-title-items {
  margin-top: 100px;
  background: #fef8eea8;
  padding: 50px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  @media screen and (max-width: 768px) {
    margin-top: 50px;
    padding: 25px 0;
    margin-left: -20px;
    margin-right: -20px;
  }
}

.title-items {
  font-weight: 500;
  font-size: 45px;
  color: #ed8a26;
  padding-bottom: 30px;
  display: flex;
  padding-left: 10px;
  @media screen and (max-width: 768px) {
    text-align: center;
    width: 100%;
    //justify-content: center;
    font-size: 30px;
    padding: 10px 10px;
  }
}

.items {
  width: 100%;
  font-size: 20px;
}

.ant-layout {
  background-color: #eff0f3;
  width: 100%;
}

.faq-collapse-title {
  font-size: 28px;
  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 0.1;
  }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  @media screen and (max-width: 768px) {
    line-height: 1.5;
    color: #1d1d1f;
  }
}

/**********CONTAINER ACCUEIL + IMAGE*****************/
.home-image {
  background-image: url("../../assets/HeroSection.jpg");
  background-size: cover;
  //min-height: 500px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 768px) {
    min-height: 0px;
    margin-top: 60px;
  }
}

.home-container {
  display: flex;
  flex-direction: column;
  //width: 100%;
  margin-left: 200px;
  margin-right: 200px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: block;
  }
}

.no-br {
  display: bloc;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

// Animation
/**
 * ----------------------------------------
 * animation focus-in-expand-fwd
 * ----------------------------------------
 */
.focus-in-expand-fwd {
  -webkit-animation: focus-in-expand-fwd 0.8s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: focus-in-expand-fwd 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes focus-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-800px);
    transform: translateZ(-800px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes focus-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-800px);
    transform: translateZ(-800px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tracking-in-expand-fwd-top
 * ----------------------------------------
 */

.tracking-in-expand-fwd-top {
  -webkit-animation: tracking-in-expand-fwd-top 0.8s
    cubic-bezier(0.165, 0.84, 0.44, 1) both;
  animation: tracking-in-expand-fwd-top 0.8s cubic-bezier(0.165, 0.84, 0.44, 1)
    both;
}

@-webkit-keyframes tracking-in-expand-fwd-top {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(-500px);
    transform: translateZ(-700px) translateY(-500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd-top {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(-500px);
    transform: translateZ(-700px) translateY(-500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
