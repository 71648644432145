#sperator-line{
  @media screen and (max-width: 768px) {
    margin-left: 5px !important;
  }
}
.step-otp-title {
  @media screen and (max-width: 768px) {
    font-size: 24px;
    height: 28px;
    font-weight: bold;
    margin: 20px 10px;
  }
  
}

.step-otp-confirm-block {
  margin-bottom: 65px;
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }
}

.step-otp-card {
  @media screen and (max-width: 768px) {
    background: transparent !important;
    margin-bottom: 90px;
  }
}
.send-code-otp {
  display: flex;
  flex-direction: column;
  margin: 40px;
  gap: 70px;
  @media screen and (max-width: 768px) {
    margin: 5px;
  }
}

.sendMessage {
  display: flex;
  align-items: center;
  gap: 10px;
}

.anticon-mail {
  background-color: #fff3e0;
  width: 30px;
}

.anticon-mail svg {
  height: 2em !important;
  width: 2em !important;
  padding-left: 2px;
  color: #ed8a26 !important;
}

.text {
  font-size: 16px;
  color: gray;
  //padding-left: 6px;
}

.number {
  font-weight: bold;
  color: black;
}

.ant-otp {
  width: 85%;
  // height: 67px;
  display: flex;
  align-items: center;
  margin: 60px auto;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    width: 100%;
    gap: 0.5px;
  }
}

.ant-otp-input {
  width: 58px;
  height: 67px;
  @media screen and (max-width: 768px) {
    //width: 300px !important;
    height: 67px !important;
    width: 45px !important;
    border: none;
  }

}

.ant-form-item {
  @media screen and (max-width: 768px) {
    padding: 0;
  }
}
.ant-otp .ant-otp-lg {
  background-color: #fff !important;
}

.codeOTP {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  color: gray;
  margin-top: 10px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.help {
  margin-top: 30px;
}

.contactHelp {
  display: flex;
  justify-content: center;
  font-size: 19px;
  color: gray;
}

.codeRenvoyer span {
  padding-left: 4px;
  font-weight: bold;
  color: black;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
  
}

.ant-modal-content {
  // width: 650px;
  height: 600px;
  margin-top: 0px;
}

.total {
  padding-bottom: 15px;
}

.result {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: auto;
  margin-bottom: 10px;
}

.titree {
  color: #1d1d1fa6;
  font-size: 18px;
  font-weight: 500;
}

.valeur {
  color: black;
  font-weight: 500;
  font-size: 18px;
}

.bg {
  background-color: transparent !important;
}
