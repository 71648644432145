.step-winner-card {
  
  @media screen and (max-width: 768px) {
    background-color: transparent !important;
    margin-top: 0px;
  }
}
.container-img {
  position: relative;
  text-align: center;
  height: 150px;
}

.img {
  width: 100%;
}

.step-image-text {
  //position absolute met le texte en haut, top met l'espace entre le container et le texte
  position: absolute;
  top: 60px;
  font-size: 28px;
  text-align: center;
  left: 60px;
  right: 60px;
  @media screen and (max-width: 768px) {
    margin-left: 0;
    left: 10px;
    right: 10px;
  }
}
.step-image-text span {
  font-weight: 400;
}

.container-gain {
  display: flex;
  flex-direction: column;
  margin: 25px 40px;
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
}

.gain {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;
}

.mtn-text {
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  color: #0000007d;
}

.step-winner-amount {
  width: auto;
  padding-left:100px;
  padding-right: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eff0f3;
  border-radius: 8px;
  @media screen and (max-width: 768px) {
    background-color: #E3E6EE;
    width: 100%;
    padding-left:0;
    padding-right: 0;
  }
}

.mtn {
  font-size: 40px;
  font-weight: 600;
}

.fcfa {
  font-size: 30px;
  font-weight: 400;
  padding-left: 10px;
}

.infoCircle {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #0000004a;
}

#success {
  width: 100%;
  height: 50px;
  background-color: #1e7735;
  border-color: #1e7735;
  border-radius: 10px;
  color: #ffffff;
  font-size: 18px;
}
